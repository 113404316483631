.button {
  color: inherit;
  background: var(--color-buttonBackground);
  text-transform: none;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: normal;
  min-width: 0;
  height: 28px;

  &:hover {
    background: lightGrey;
  }
}

.rounded {
  background: var(--color-primary);
  color: white;
  border-radius: 2em;
  &:hover {
    background: var(--color-primary);
  }
}

.nowrap {
  white-space: nowrap;
}

.autotestNoIdWarn {
  border: 1px solid red;
}
