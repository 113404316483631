.shadow {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.rounded {
  overflow-x: clip;
  overflow-y: clip;
  border-radius: 0.5em 0.5em 0.5em 0.5em;
}

.rightBorder {
  border-right: 1px solid lightGrey;
}

.leftBorder {
  border-left: 1px solid lightGrey;
}

.topBorder {
  border-top: 1px solid lightGrey;
}

.verticalScroll {
  overflow-x: auto;
  overflow-y: auto;
  height: 0;
}
