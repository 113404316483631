.FormFieldList_SearchIcon {
  cursor: pointer;
  color: #999;
  padding: 4px;
  border-radius: 100%;
  transition: all 0.3s;

  &:hover {
    background: #00000011;
    color: black;
    transition: all 0.3s;
  }
}
