.container {
  display: flex;
  height: 100%;
  position: relative;
  box-sizing: content-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  overflow: hidden;
}

.editArea {
  position: absolute;
  background-color: white;
  border: none;
  border-radius: 8px;
  word-break: break-word;
  resize: none;
  overflow: hidden;
  outline: none;
  min-height: 1.25em;
  box-sizing: content-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;

  &::placeholder {
    font-weight: 400;
    color: #999999;
  }
}

.readArea {
  word-break: break-word;
  min-height: 1.25em;
  flex-grow: 1;
}
