$cellVertPadding: 1em;

.footer {
  flex-grow: 0;
  min-height: 36px;
  border-top: 1px solid #eee;
}

.headRow {
  position: relative;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.row {
  position: relative;
  &:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  &:hover .overlay {
    opacity: 1 !important;
  }
}

.headCell {
  padding: $cellVertPadding 0;
  font-size: 0.9rem;
  // background: white;
  // overflow-x: hidden;
  // display: table-cell;
}

.cell {
  padding: $cellVertPadding 0.5em;
  cursor: pointer;  
}

.cellContent {
  // padding: 0 0.5em;
  // display: flex;
  display: block;
  align-items: center;

  // white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  // text-overflow: ellipsis;
}

.selected {
  background: #edfafc;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.autotestNoIdWarn {
  border: 1px solid red;
}

.actionButton {
  transition: all 0.5s;
  :hover {
    color: var(--color-primary);
    transition: all 0.5s;
  }
}

.tooltipsCellContent{
  display: block;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.tooltipsCellContentIsOverflow{
  display: block;
  align-items: center;

  overflow: auto;
  text-overflow: ellipsis;
  max-width: 452px;
}

.tooltipsCellContent .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;

}

.tooltipsCellContent:hover .tooltiptext {
  visibility: visible;
}