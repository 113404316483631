.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0b2b48;

  svg {
    margin-right: 4px;
  }
}

.needSaveChanges {
  color: #e53935;
}
