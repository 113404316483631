:root {
  /* Deprecated color variables */
  --color-BondiBlue1: #0eb9c3;
  --color-BondiBlue2: #009aa6;

  --color-ShadesOfGrey2: #b9cddc;
  --color-ShadesOfGrey3: #dfe9f1;
  --color-ShadesOfGrey4: #f2f8fe;

  --color-Orange1: #e17d35;

  --color-Modal-BG: rgba(223, 233, 241, 0.59);

  --color-grey: #cfd8dc;
  --color-darkGrey: #999;
  --color-ligthGrey: lightGrey;
  --color-ligthLightGrey: #f1f2f4;
  --color-white: #ffffff;
  --color-red: #e53935;
  --color-green: #078556;
  --color-blue: #039be5;
  --color-yellow: #ffa000;
  --color-yellowLight: #ffecb3;
  --color-pink: #e91e63;
  --color-purple: #9c27b0;
  --color-orange: #ff5722;
  --color-cyan: #00acc1;
  --color-primary: #002A42;
  --color-primaryLight: #00838f;
  --color-appToolbarBackgroundColor: #0b2b48;
  --color-gridCellBorderColor: #e0e0e0;
  --color-toolbarBackgroundColor: #ffffff;
  --color-tabsBackgroundColor: #eceff1;
  --color-headersBackgroundColor: #ffffff;
  --color-selectedItem: #edfafc;
  --color-selectedItemDark: #b2ebf2;
  --color-mainBackground: #e2eaf2;
  --color-buttonBackground: #f1f2f4;
  --color-inputFieldBackground: #f1f2f4;
  --color-borderColor: #e0e0e0;
}
