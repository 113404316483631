.input {
  font-size: 14px;
  font-family: Inter, sans-serif;
  border: none;
  font-weight: 600;
  padding: 0;
  margin: 0;
  background-color: transparent;
  outline: none;
  width: 100%;
  white-space: nowrap;
  position: absolute;
  left: 0;
  max-width: 200px;

  &:invalid {
    color: #e53935;
  }

  &::placeholder {
    color: #6C737F;
    font-weight: 400;
  }
}

.hiddenElement {
  font-size: 14px;
  font-family: Inter, sans-serif;
  border: none;
  font-weight: 600;
  padding: 0;
  margin: 0;
  visibility: hidden;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}

.itemWrapper {
  display: flex;
  font-size: 14px;
  color: #111927;
}

.editableWrapper {
  position: relative;
}

.wrapper {
  margin: 2px;
  border-radius: 8px;
  gap: 4px;
  box-sizing: border-box;
  padding: 2px 8px;
  border-style: solid;
  border-width: 1px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
}
