.toolbarPlace {
  width: 0;
  z-index: 1;
  overflow-x: 'auto';
}

.toolbar {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.divider {
  height: 100%;
}

.filler {
  flex-grow: 1;
}

.apply {
  flex-grow: 2;
}

.text {
  white-space: nowrap;
}

.custom {
  display: flex;
  flex-direction: row;
}
