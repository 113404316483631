/* .resizer {
  width: 15px;
}

.Resizer.vertical {
  width: 15px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
} */

.cjjWdp {
  opacity: 0.15;
  /* width: 0; */
  /* background: transparent; */
  z-index: 100;
}

/*
.fXAXjb {
  width: 0 !important;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
} */
