@import url('./styles/colors.css');
@font-face {
  font-family: 'Inter';
  src: url('../public/docs/fonts/Inter/Inter-VariableFont_opsz\,wght.ttf') format('woff2');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/docs/fonts/PlusJakarta/PlusJakartaSans-VariableFont_wght.ttf') format('woff2');
}

html,
body,
table {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow: hidden;
}

/* body {
  direction: rtl;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[contenteditable]:focus {
  /* outline: 1px solid grey; */
  outline: none;
  border: 1px solid grey;
  border-radius: 3px;
  background: white;
  color: black;
}

.noSelectText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
