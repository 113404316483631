.paper {
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    background: var(--color-ShadesOfGrey4);
  }
}
.secondary_text {
  font-size: 0.8em;
  opacity: 0.5;
}